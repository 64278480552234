@import 'reset';
@import 'fonts';
@import 'components';
@import 'pages';
@import 'variables';
@import 'global';
@import 'animations';

* {
  outline: none;
}

html,
body {
  height: auto;
  min-height: 100vh;
}

body {
  position: relative;
  background-color: #fafafa;
}
.react-confirm-alert-body-element{
  overflow: hidden;
}

a,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
input,
label,
td,
td,
th,
button,
textarea,
div {
  font-family: $main-font;

}

a,
p,
input,
textarea,
span {
  color: $dark;

  b {
    font-weight: 600;
  }
}

h1 {
  font-size: 35px;
  padding: 20px;
  color: #3088e4;
}

h2 {
  font-size: 25px;
  margin-bottom: 30px;
  font-weight: 600;
}

a {
  text-decoration: none;

  &:active,
  &:focus,
  &:visited {
    color: $dark;
  }
}

input,
textarea {
  box-sizing: border-box;
  border: none;
  outline: none;

  &::placeholder {
    font-family: $main-font;
    color: $gray-medium;
  }
}

input,
.bordered {
  border: 1px solid $gray-light;
}

button {
  border: 0;
  cursor: pointer;
  font-size: 14px;
}
