@font-face {
  font-family: "Suisse Intl", sans-serif;
  src: local("Suisse Intl") url("../fonts/SuisseIntl-Regular-WebM.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Suisse Intl", sans-serif;
  src: local("Suisse Intl") url("../fonts/SuisseIntl-SemiBold-WebM.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
