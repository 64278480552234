// Fonts
$main-font: "SuisseIntl-Regular", sans-serif;


// Media BreakPoints
$xl: 1549px;
$lg: 1199px;
$md: 991px;
$xs: 767px;

// Project Color Guide (From Customer)
/* Big text elements: Head, Lead, Paragraphs. And hover effects. */
$dark: #323232;

/* Small text Elements: Paraphs, title, text */
$gray-dark: #a0a0a0;

/* For Menubar, larger Text-Elements, boxes, Lines, chosen Elements */
$gray-medium: #d2d2d2;

/* Only for the Information Bar */
$gray-light: #ebebeb;

/* Only as the background color and for the buttons on the Homescreen */
$almost-white: #fafafa;

/* For titles and links */
$main-blue: #3088e4;

