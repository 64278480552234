@import '../variables';

.left-menu {
  position: fixed;
  top: 42px;
  height: calc(100% - 42px);
  width: 100%;
  overflow-y: hidden;
  transition: transform 0.5s;
  pointer-events: none;
  z-index: 2000;

  &.active {
    pointer-events: all;

    .left-menu-content {
      transform: translateX(0);
      transition: transform 0.3s;
    }
    
    .left-menu-backdrop {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &-content {
    position: fixed;
    width: 310px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: calc(100vh - 30px);
    transform: translateX(-100%);
    transition: transform 0.5s;
    z-index: 1000;
    left: 0;
  }

  &-backdrop {
    background-color: transparent;
    transition: background-color 0.2s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500;
    cursor: pointer;
  }

  &__link {
    text-transform: uppercase;
    letter-spacing: 0.3px;
    padding: 10px 30px;
    transition: background-color 0.2s;

    &:hover {
      color: #3088e4;
      transition: background-color 0.2s;
    }

    &:last-child {
      border-bottom: 1px solid #eaeaea;
    }

    & + & {
      border-top: 1px solid #eaeaea;
    }
  }
}
