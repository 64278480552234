.spinner,
.spinner:after {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  user-select: none;
}
.spinner {
  position: relative;
  border-top: 10px solid rgba(50, 50, 50, 0.5);
  border-right: 10px solid rgba(50, 50, 50, 0.5);
  border-bottom: 10px solid rgba(50, 50, 50, 0.5);
  border-left: 10px solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 350px;
    text-align: center;

    &__full {
      position: fixed;
      // z-index: 1;
      z-index: 999999;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }

  &-message {
    margin-top: 50px;
    font-size: 22px;
    font-style: oblique;
    color: #3088e4 ;
    user-select: none;
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
