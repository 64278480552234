// citivin-frontend/src/assets/icons/freeze.svg
.list-item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .entities-item{
        flex: 1 0 auto;
    }
    .year-disabled {
        opacity: 0.2;
    }
    .year-freeze,
    .year-unfreeze,
    .years-goals-freeze,
    .years-goals-unfreeze {
        width: 15px;
        height: 15px;
        margin-left: 10px;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: center;
    }

    .year-freeze {
        background-image: url('../../icons/freeze.svg');
    }

    .year-unfreeze {
        background-image: url('../../icons/forward.svg');
    }

    .years-goals-freeze {
        background-image: url('../../icons/freeze-goals.svg');
    }
    
    .years-goals-unfreeze {
        background-image: url('../../icons/unfreeze-goals.svg');
    }
}
