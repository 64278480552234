@import '../variables';

.login-form {
  &-footer {
    display: flex;
    flex: auto;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
}

.profile-form{
  width: 50%;
}

.baar-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding-bottom: 18px;
}

.success-message{
  text-align: center;
  margin-top: 10px;
}

.reset-password{
  display: flex;
  .reset-password-button{
    color: $dark;

    a {
      text-decoration: underline;

      &:hover {
        color: $main-blue;
      }
    }
  }
}


.form {
  &_modal {
    &__close {
      position: absolute;
      display: block;
      height: 15px;
      width: 15px;
      color: rgba(210,210,210, 1);
      cursor: pointer;
      top: 15px;
      left: 25px;
      z-index: 1801;
    }
  }

  &-row {
    display: flex;
    flex-direction: row;
  }

  &-col {
    width: 50%;

    & + & {
      width: calc(50% - 25px);
      padding-left: 25px;
    }
  }

  &-group {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
  }

  &-body {
    .full-report {
      &__content {
        flex-direction: row;
        flex-wrap: wrap;
      }

      &__block {
        height: 100%;
        margin: 30px 40px 0 0;
        padding: 30px;
        border: 1px solid #3088e4;
        border-radius: 7px;

        &--disabled {
          border-color: $gray-dark;
        }
        
        .form__checkbox-field_header{
          margin-bottom: 20px;
        }
      }

      &__divisions {
        margin-top: 20px;
      }
    }
  }

  &__checkbox-text {
    font-size: 14px;
    font-weight: 600;
  }

  &__checkbox-text_header {
    font-size: 35px;
    color: #3088e4;
  }

  &__checkbox-icon {
    width: 30px;
    height: 30px;
  }

  &__checkbox-field, &__checkbox-field_header {
    label {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
    }

    input[type="checkbox"] {
      display: none;
    }
    
    input[type="checkbox"] + label .form__checkbox-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    
    input[type="checkbox"] + label .form__checkbox-icon {
      background: url("../../icons/checkbox-unchecked.svg") center no-repeat;
      background-size: contain;
    }
    
    input[type="checkbox"]:checked + label .form__checkbox-icon {
      background: url("../../icons/checkbox-checked.svg") center no-repeat;
      background-size: contain;
    }
    
    input[type="checkbox"]:disabled + label  {
      & .form__checkbox {
        &-text_header {
          color: $gray-dark;
          cursor: initial;
        }

        &-icon {
          background: url("../../icons/checkbox-disabled.svg") center no-repeat;
          background-size: contain;
        }
      }
    }
  }

  &-area-group {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: 10px;

    .form__field {
      margin-top: 0 !important;
    }

    & + & {
      padding-top: 20px;
      margin-top: 20px;
      border-top: 1px solid #eaeaea;
    }

    .field {
      margin: 0;

      &:first-child {
        border-right: 0;
      }
    }
  }

  &-label {
    color: gray;
    margin-bottom: 10px;
    font-size: 14px;
  }


  &-label-black {
    color: black;
    margin-bottom: 10px;
    font-size: 14px;
  }

  &-panel {
    &-gray {
      width: 100%;
      background-color: $gray-light;
      margin: 36px 0 18px;

      &.first {
        margin: 0px 0px 18px 0px;
      }

      &.people {
        margin: 68px 0 18px
      }

      &.no-margin {
        margin-top: 0px;
      }

      &-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__text {
        position: relative;
        color: $dark;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.3px;
        padding: 4px 0;
        white-space: nowrap;

        &_50 {
          width: 50%;
          display: inline-block;
          color: $dark;
          font-size: 14px;
          padding: 3px 0;
          font-weight: 500;
          white-space: nowrap;
        }
      }

      &__input {
        padding: 5px 15px;
        font-size: 12px;
        border-top: 1px solid $gray-light;
        border-bottom: 1px solid $gray-light;
      }
    }

    &__remove {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: $gray-dark;
      color: white;
      font-size: 10px;
      border-radius: 4px;
      padding: 2px 5px;
      height: 20px;
    }
  }

  &-controls {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
    height: 36px;
  }

  &-body {
    margin-top: 115px;
    border-top: solid 1px #ebebeb;
    padding-bottom: 80px;
    height: calc(95vh - 205px);
    overflow-y: auto;

    &_no-margin {
      margin-top: 32px;
      border-top: none;
      padding-bottom: 25px;
      height: calc(95vh - 100px);
    }
  }

  &-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__field {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 14px;

    & + & {
      margin-top: 5px;
    }

    &_readonly {
      background-color: #fafafa;
      border: 0;
      padding: 0 !important;
      -webkit-appearance: none;
    }

    &_no-margin {
      margin-top: 0 !important;
    }

    &_big {
      font-size: 35px;
      color: #3088e4;
      font-weight: 500;
      padding: 0px 5px;
      margin-top: 5px;
      width: 51.5%;

      &::placeholder {
        color: $gray-light;
      }
    }

    &_textarea {
      display: block;
      width: 100%;
      padding: 8px 10px;
      font-size: 14px;
      min-height: 120px;
      resize: none;

      &_policy {
        max-width: 52%;
        min-height: 120px;
      }
    }

    &_password {
      padding-right: 50px;
    }

    &-wrapper {
      position: relative;
    }

    &_eye {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      height: 20px;
      width: 20px;
      display: block;
      background-image: url('../../icons/eye.svg');
      cursor: pointer;
      opacity: 0.2;
      transition: opacity 0.5s;

      &:hover {
        opacity: 1;
        transition: opacity 0.5s;
      }

      &_active {
        opacity: 1;
      }
    }
  }

  &__button {
    & + & {
      margin-right: 10px;
    }
  }
}
