@import '../variables';

.entities {
  &-list {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 400px;

    &_priorities {
      width: 1075px;
    }

    @media screen and (max-width: 767px) {
      width: 300px;
    }

    &_no-margin {
      margin-top: 10px;
    }
  }

  &-item {
    border: 1px solid $gray-medium;
    max-height: 170px;
    overflow-y: auto;
    padding: 10px;
    background-color: white;
    opacity: 1;
    transition: opacity 0.5s;
    margin-top: 10px;

    &.hoverable {
      cursor: pointer;

      &:hover {

        .entities-item__title {
          color:#3088e4;
;
        }
      }
    }

    & + & {
      margin-top: 10px;
    }

    &.deletable {
      position: relative;

      &:hover {
        background-color: white;

        .entities-item__title {
          color: #3088e4;
        }

        .entities-item__delete {
          cursor: pointer;

          &:after {
            display: block;
          }
        }
      }
    }

    &.fixed {
      background-color: #fafafa;
    }

    &.hided {
      transition: opacity 0.5s;
      opacity: 0;
    }

    &.selected {
      background-color: $dark;

      .entities-item__title {
        color: #fff;
      }
    }

    &.small {
      .entities-item__title {
        font-size: 12px;
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.2;
    }

    &__delete {
      &:after {
        display: none;
        position: absolute;
        content: '';
        background-image: url('../../icons/trash-bucket.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        height: 15px;
        width: 15px;
      }
    }
    &__title {
      font-weight: 600;
      font-size: 14px;
    }
  }
}
