@import '../variables';
.page{
  .modal{
    .modal-content{
      transform: translateX(-11%);
    }
  }
  .modal:last-child{
    .modal-content{
      width: 90%;
      max-width: 90%;
      transform: translateX(0);
      &.close{
        transform: translateX(100%);
      }
    }
  }
}
.modal {
  display: flex;
  flex-direction: row-reverse;
  position: fixed;
  top: 42px;
  height: calc(100% - 42px);
  width: 100%;
  overflow-y: hidden;
  transition: all 0.5s;
  z-index: 1800;
  opacity: 1;
  pointer-events: all;

  .modal-content {
    transform: translateX(0);
    animation: appearing-content 0.8s;
    box-shadow: -7px 0px 5px -5px $gray-medium;
    @keyframes appearing-content {
      from {
        transform: translateX(100%);
      }
      to {
        transform: translateX(0);
      }
    }
  }

  .modal-backdrop {
    transition: background-color 0.2s;
  }

  &-backdrop {
    background-color: transparent;
    transition: background-color 0.2s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500;
    cursor: pointer;
  }

  &-content {
    position: relative;
    background-color: #fafafa;
    height: calc(100% - 50px);
    padding-bottom: 50px;
    width: 90%;
    max-width: 90%;
    z-index: 1000;
    overflow: hidden;
  }
}

#react-confirm-alert {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
}

.react-confirm-alert {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  opacity: 1;
  animation-name: appearing;
  animation-duration: 0.5s;
  width: auto;
  min-width: 200px;
  max-width: 60vw;

  -webkit-box-shadow: 0px 0px 27px -5px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0px 0px 27px -5px rgba(0, 0, 0, 0.34);
  box-shadow: 0px 0px 27px -5px rgba(0, 0, 0, 0.34);

  &-body {
    display: flex;
    flex-direction: column;

    &-top {
      background-color: #ebebeb;
      padding: 12px;
    }

    &-bottom {
      padding: 25px 15px 15px 15px;;
    }

    &__title {
      display: block;
      margin: 0 auto;
      max-width: 80%;
      text-align: center;
      line-height: 22px;
      font-family: $main-font;
      font-size: 16px;
      color: $dark;
    }
  }

  &-button-group {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    button {
      background-color: #a0a0a0;
      color: #fff;
      border-radius: 5px;
      padding: 5px 35px;
      margin-right: 5px;
      margin-left: 5px;
      font-family: $main-font;
      font-size: 14px;

      &:hover {
        background-color: #3088e4;
        ;
      }
    }
  }

  @keyframes appearing {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &-overlay {
    position: fixed;
    z-index: 4000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
