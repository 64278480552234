@import '../variables';

.top-bar {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #eaeaea;

  &-tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 42px;
  }

  &-left {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: auto;
  }

  &-mid {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;

    &__year {
      white-space: nowrap;
      padding-left: 10px;

      a {
        display: inline-block;

        &,
        &:active,
        &:visited {
          color: $main-blue;
          font-weight: 600;
        }
      }
    }
  }

  &-logo {
    width: 100px;
    padding-left: 10px;
  }

  &-right {
    height: 42px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &-icon-wrapper {
    padding: 4.5px 10px;
    background-color: #fff;
    height: 100%;
  }

  &-login {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 15px;
    cursor: pointer;

    &__text {
      font-size: 14px;
      text-transform: uppercase;
      margin-right: 10px;
    }
  }

  &-tab {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 20px;

    & + & {
      &:last-child {
        background-color: #fafafa;;
      }
    }

    &__text {
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  &__menu-icon {
    position: relative;
    height: 30px;
    width: 25px;
    transition: transform 0.5s;
    cursor: pointer;
  }
}
