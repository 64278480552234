@import 'components/topbar';
@import 'components/left-menu';
@import 'components/login-form';
@import 'components/modals';
@import 'components/forms';
@import 'components/spinner';

.report {
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  padding-top: 20px;

  p {
    // word-wrap: break-word;
    // -moz-hyphens: auto;
    // -o-hyphens: auto;
    // -webkit-hyphens: auto;
    // -ms-hyphens: auto;
    // hyphens: auto;
  }

  &-controls {
    &__title {
      display: block;
      width: 100%;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  &-buttons {
    padding-top: 0;
    border-top: 0;
    margin-top: 20px;
  }

  &-configure__icon {
    display: block;
    width: 15px;
    height: 15px;
    cursor: pointer;
    background-image: url('../../assets/icons/settings.svg');
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 20px;
  }

  &-pdf {
    padding-top: 20px;
    border-top: 1px solid $gray-medium;

    &-content {
      display: flex;
      flex-direction: column;
    }

    &__btn {
      display: flex;
      flex-direction: row;
      text-align: left;
      justify-content: space-between;
      padding: 10px;
      background-color: #ebebeb;
      border-radius: 4px;
      width: 380px;

      &:hover {
        background-color: $gray-medium;
      }

      & + & {
        margin-top: 10px;
      }
    }

    &__division {
      display: flex;
      flex-direction: row;
      text-align: left;
      justify-content: space-between;
      padding: 10px;
      margin-right: 10px;
      color: #fff;
      background-color: #a0a0a0;
      border-radius: 4px;
      width: 245px;

      &:hover {
        background-color: $gray-medium!important;
      }
    } 

    &__icon {
      display: block;
      width: 15px;
      height: 15px;
      cursor: pointer;
      background-image: url('../../assets/icons/download.svg');
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 20px;
    }
  }

  &-links {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-top: 20px;
  }

  &-item {
    display: flex;
    flex-direction: row;

    & + & {
      margin-top: 50px;
    }
  }

  &-col {
    &:first-child {
      width: 400px;
      min-width: 400px;
    }

    &:nth-child(2) {
    }
  }

  &-priorities {
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    &-row {
      display: flex;
      font-size: 14px;

      &__description {
        font-size: 14px;
      }
    }

    &-content {
      width: 400px;
      min-width: 400px;
    }
  }

  &-mid-term-goals {
    &-content {
      display: flex;
      flex-direction: column;
      width: 500px;
      min-width: 500px;
    }

    &-row {
      display: flex;
      flex-direction: row;
      font-size: 14px;

      & + & {
        margin-top: 25px;
        border-top: 1px solid $gray-medium;

        .report-agencies-info__title {
          padding: 10px 0;
        }

        .report-mid-term-goals-content {
          padding: 10px 0;
        }
      }
    }

    &-info {
      display: flex;
      flex-direction: row;

      &__title {
        color: $gray-dark;
        font-weight: 600;
        letter-spacing: 0.3px;
        word-break: break-word;
        width: 200px;
        min-width: 200px;
      }

      &__description {
        margin-left: 15px;
      }
    }
  }

  &-agencies {
    &-row {
      display: flex;
      flex-direction: row;
      font-size: 14px;

      & + & {
        padding: 10px 0;
        margin: 10px 0;
        border-top: 1px solid $gray-medium;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      width: 150px;
      min-width: 150px;
    }
  }

  &-division {
    &-row {
      display: flex;
      flex-direction: row;
      font-size: 14px;

      & + & {
        margin: 10px 0;
        padding: 10px 0;
        border-top: 1px solid $gray-medium;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      width: 210px;
      min-width: 210px;
    }
  }

  &-stg {
    &-row {
      display: flex;
      flex-direction: row;
      font-size: 14px;
      padding-bottom: 10px;

      & + & {
        padding: 10px 0;
        margin: 10px 0;
        border-top: 1px solid $gray-medium;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      width: 500px;
      min-width: 500px;
    }
  }

  &-status {
    &-content {
      width: 150px;
      min-width: 150px;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    display: block;
  }

  &__description {
    font-size: 14px;
  }

  &__link {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: $dark;
    font-weight: 600;
    padding: 15px 25px;
    font-size: 18px;
    line-height: 22px;
    background-color: $gray-light;
    border-radius: 4px;

    &:hover {
      background-color: $gray-medium;
    }

    & + & {
      margin-left: 10px;
    }
  }
}

.title-btn-wrapper {
  justify-content: space-between;
  align-items: baseline;
  button {
    width: auto;
  }
}

.profile-dropdown{
  position: relative;
  color: $dark;
  height: 42px;
  width: 250px;
  margin-right: 100px;


  .profile-dropdown-modal{
    display: none;
    box-shadow: -5px 5px 5px -5px $gray-medium;
    height: auto;
    background-color: #fafafa;
    &.is-active {
      display: block;
    }

    .dropdown-item{
      padding: 10px 15px;
      border-bottom: 1px solid $gray-light;
    }
  }

  &-toggle {
    cursor: pointer;
    height: 100%;

    &__name {
      font-size: 15px;
      font-weight: 600;
      padding-left: 20px;
      float: right;
      margin-top: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      
    }

    &__icon {
      display: inline-block;
      background-image: url('../../assets/icons/arrow-down.svg');
      background-size: contain;
      background-repeat: no-repeat;
      height: 10px;
      width: 10px;
      margin-left: 15px;
      transform: rotate(180deg);
      padding: 1px;

      &.is-active {
        transform: rotate(0);
      }
    }
  }
}

.dropdown {
  position: relative;
  color: $dark;
  background-color: #fff;

  .dropdown {
    .dropdown-toggle {
      background-color: #fafafa;
      border-bottom: 1px solid $gray-light;
    }

    .dropdown-toggle__name {
      font-size: 16px;
      padding-left: 40px;
    }
  }

  &-toggle {
    padding: 20px 0;
    cursor: pointer;
    border-bottom: 1px solid $gray-medium;
    background-color: #fafafa;

    &__name {
      font-size: 20px;
      font-weight: 600;
      padding-left: 20px;
    }

    &__icon {
      display: inline-block;
      background-image: url('../../assets/icons/arrow-down.svg');
      background-size: contain;
      background-repeat: no-repeat;
      height: 10px;
      width: 10px;
      margin-right: 10px;
      transform: rotate(180deg);

      &.is-active {
        transform: rotate(0);
      }
    }
  }

  &-content {
    position: relative;
    height: auto;
    max-height: 0;
    overflow: hidden;

    &.is-expanded {
      max-height: 150vh;
      overflow-y: auto;
    }
  }
}

.short-report {
  padding-bottom: 100px;

  p {
    // word-wrap: break-word;
    // -moz-hyphens: auto;
    // -o-hyphens: auto;
    // -webkit-hyphens: auto;
    // -ms-hyphens: auto;
    // hyphens: auto;
    word-break: break-word;
  }

  &-title {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-row {
    padding-bottom: 20px;

  }

  &-mtg {
    & + & {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #eaeaea;
    }
  }

  &-stg {
    & + & {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #eaeaea;
    }

    &__title {
      // word-wrap: break-word;
      // -moz-hyphens: auto;
      // -o-hyphens: auto;
      // -webkit-hyphens: auto;
      // -ms-hyphens: auto;
      // hyphens: auto;
    }
  }

  &_bordered {
    & + & {
      padding-top: 10px;
      margin-top: 10px;
      border-top: 1px solid #eaeaea;
    }
  }

  &__description {
    font-weight: 600;
    line-height: 20px;

    &_cut {
      max-width: 60%;
      letter-spacing: 0.3px;
    }
  }
}

.statistic-wrapper{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.statistic-item{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 5px;
  background: #e7e7e7;
  line-height: normal;
  padding: 20px 15px;
  color: #9b9b9b;
  width: calc(50% - 10px);
  box-sizing: border-box;
  &__name{
    color: #000;
    font-size: 24px;
    display: block;
    margin: 0 auto;
  }
  &__number{
    color: #000;
    font-size: 24px;
    margin-left: 10px;
  }
}
