.users-item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .entities-item{
        flex: 1 0 auto;
        min-width: 900px;
        width: 900px;
        div {
            display: inline-block;
        }
        .user-name {
            width: 200px;
            min-width: 200px;
            margin: 0 10px;
        }
        .user-email {
            width: 400px;
            min-width: 400px;
            margin: 0 10px;
        }
        .user-role {
            width: 100px;
            min-width: 100px;
            margin: 0 10px;
        }
        .user-status {
            float: right;
            margin: 0 30px 0 10px;
        }
        .status-completed {
            color: green;
        }
        .status-invited {
            color: lime;
        }
    }
}

.pagination-list{
    display: flex;
    margin: 14px 0;
    .pagination-button{
        margin-right: 14px;
        cursor: pointer;
        width: 28px;
        height: 28px;
        background-color: white;
        border: 1px solid #d2d2d2;
        &__number{
            width: fit-content;
            margin: 7px auto;
            font-weight: 600;
        }

    }

    .active{
        background-color: #d2d2d2;
    }


}