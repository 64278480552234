@import '../variables';

.login-form {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 400px;
  
  &__content {
    display: flex;
    flex-direction: column;
  }
}