@import "variables";

select {
  max-height: 25px;
}

.textarea-shadowInset-padding5-marginLeft5 {
  box-shadow: inset 0 0 1px 1px rgba(112, 160, 219, 0.6);
  padding: 5px;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100% - 50px);
  padding: 0 25px;
  margin: 0 auto;

  &_division {
    padding-top: 0px;

    & + & {
      border-top: 1px solid #eaeaea;
      padding-top: 9px;
      margin-top: 36px;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 -15px;

  &_no-margin {
    margin: 0;
  }

  & + & {
    margin-top: 20px;
  }
}

.col {
  padding: 0 15px;

  &_half {
    width: calc(50% - 20px) !important;
    padding: 0 5px 0 15px !important;
  }

  &:first-child {
    width: calc(40% - 30px);
  }

  &:nth-child(2) {
    width: calc(40% - 30px);
  }

  &:nth-child(3) {
    width: calc(20% - 30px);
  }

  &_goals {
    width: calc(60% - 30px) !important;
  }

  &_header {
    & + & {
      box-sizing: border-box;
      // border-left: 1px solid $gray-medium;
    }
  }
}

.page {
  padding-top: 154px;
  min-height: calc(100vh - 154px);
  position: relative;

  &-header {
    position: fixed;
    top: 32px;
    width: 100%;
    z-index: 1500;
    background-color: white;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
  }

  &-content {
    padding-bottom: 100px;
    padding-top: 36px;
  }

  &__title {
    font-weight: 500;
    padding-top: 68px;
    padding-bottom: 20px;
    padding-left: 0;
  }
}

.alert {
  &-danger {
    color: red;
    font-size: 12px;
  }
}

.btn {
  max-width: 200px;
  padding: 10px 20px;
}

.button {
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  background-color: $gray-dark;
  color: #fff;
  border-radius: 5px;
  padding: 10px 15px;

  &_create {
    padding: 3px 10px;
    font-size: 12px;
  }

  &:hover {
    background-color: #3088e4;
  }

  &__wrapper {
    margin-top: 10px;
  }

  &.disabled,
  &:disabled {
    background-color: $gray-light;
    cursor: default;

    &:hover {
      background-color: $gray-light;
    }
  }

  &_login {
    padding: 5px 25px;
  }
}

.message {
  font-size: 14px;
}

.priorities {
  &-item {
    animation-name: appearing;
    animation-duration: 0.5s;
    opacity: 1;
  }
  &__remove-icon,
  &__report-icon {
    display: block;
    width: 15px;
    height: 15px;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 15px;
  }

  &__remove-icon {
    background-image: url("../../assets/icons/trash-bucket.svg");
  }

  &__report-icon {
    border-radius: 20px;
    background-image: url("../../assets/icons/info.svg");
    opacity: 0.5;

    &__disabled {
      opacity: 0.2;
    }
  }

  &__reason-field-wrapper {
    box-sizing: border-box;
  }

  &__reason-wrapper {
    position: relative;
    height: 15px;
    width: 15px;
    margin-left: 15px;

    input {
      opacity: 0;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }

    .priorities__report-icon {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      margin-left: 0;
    }
  }

  &__sort-handle {
    position: absolute;
    top: 30px;
    right: 4px;
    padding: 2px 2px;
    background-color: #d2d2d2;
    color: white;
    border-radius: 2px;
    cursor: pointer;
  }
}

.mid-term-goals {
  display: flex;
  flex-direction: column;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
  }

  &-item {
    position: relative;
    display: flex;
    animation-name: appearing;
    animation-duration: 0.5s;
    opacity: 1;
    // margin: 0 -15px;

    & + & {
      border-top: 1px solid #eaeaea;
      padding-top: 9px;
      margin-top: 18px;
    }

    &-content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0 15px 0 0;
      min-width: 66%;
    }

    &__remove-icon {
      position: absolute;
      right: -5px;
      top: 0;
      display: block;
      width: 15px;
      height: 15px;
      cursor: pointer;
      background-image: url("../../assets/icons/trash-bucket.svg");
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 20px;
    }
  }
}

.agencies {
  width: calc(100% - 30px);

  &-content {
    // padding: 0 15px;
    padding-left: 35px;
  }

  &-footer {
    display: flex;
    flex-direction: column;
  }

  &-list {
    margin-bottom: 10px;
  }

  &__person {
    margin: 10px 0 10px 5px;
    font-size: 14px;

    span {
      font-weight: 600;
    }
  }
}

.field {
  &-wrapper {
    position: relative;

    &_row {
      display: flex;
      flex-direction: row;

      &_shrink {
        flex-shrink: 2;
      }
    }
  }

  &-error {
    font-size: 12px;
    color: red;
    opacity: 0.8;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &-success {
    color: green;
    margin-top: 8px;
    font-size: 12px;
    opacity: 0.8;
  }
}

.division {
  &-mtg {
    &:first-child {
      min-width: 33%;
      max-width: 33%;

      .division-mtg__text {
        color: $gray-dark;
        letter-spacing: 0.3px;
        font-weight: 600;
        word-break: break-word;
      }
    }

    & + & {
      margin-left: 20px;
    }

    &__text {
      font-size: 14px;
      letter-spacing: 0.2px;
      line-height: 16px;
      // word-break: break-all;
    }
  }

  &-col {
    display: flex;
    flex-direction: column;
    padding: 0 15px;

    &_title {
      display: flex;
      flex-direction: row;
    }

    &_row {
      display: flex;
      flex-direction: row;
    }

    &:first-child {
      width: calc(40% - 30px);
    }

    &:nth-child(2) {
      width: calc(60% - 30px);
    }
  }

  &-assigned {
    margin-top: 5px;

    &__text {
      font-size: 14px;

      & + & {
        margin-top: 5px;
      }

      span {
        font-weight: 600;
      }
    }
  }

  &-actions {
    position: relative;
    min-width: 20%;
    width: 100%;
    padding: 0; // 15px;

    &__wrapper {
      margin-top: 12px;
      position: relative;
      /* 1800 default modal index */
      z-index: 1801;
    }

    &__status {
      position: absolute;
      right: -75px;
      top: 0;
      height: 25px;
    }

    &__main-status {
      display: inline-block;
      min-width: 13px;
      text-align: center;
      margin-left: 15px;
    }
  }

  &__remove-icon {
    position: absolute;
    top: 5px;
    right: 1.5px;
    display: block;
    width: 15px;
    height: 15px;
    cursor: pointer;
    background-image: url("../../assets/icons/trash-bucket.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 20px;
  }

  &__pending {
    position: absolute;
    top: 16px;
    left: 70px;
  }

  &__selected-item {
    background-color: #c4f3c4;
  }
}

.activity {
  &__message {
    font-size: 14px;
  }

  &-item {
    & + & {
      margin-top: 10px;
    }
  }

  &-col {
    display: flex;
    flex-direction: row;
    padding: 0;

    &:first-child {
      padding-left: 15px;
      width: 50%;

      textarea {
        border-right: 0;
      }
    }

    &:nth-child(2) {
      width: 20%;

      textarea {
        border-right: 0;
      }
    }

    &:nth-child(3) {
      width: 20%;

      textarea {
        border-right: 0;
      }
    }

    &:nth-child(4) {
      width: 20%;
    }

    &:nth-child(5) {
      width: 10%;
      padding-left: 15px;
    }
  }
}

.policies,
.agencies {
  &-row {
    display: flex;
    width: 100%;

    & + & {
      margin-top: 30px;
    }
  }
}

.stg {
  display: flex;
  flex-direction: row;
  width: 100%;

  & + & {
    margin-top: 18px;
  }

  &-fields {
    display: flex;
    flex-direction: column;
    width: 100%;
    // max-width: 50%;
    // min-width: 50%;
  }

  &-row-fields-upper {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &-row-fields-lower {
    display: flex;
    width: calc(100% - 72px);
    margin-top: 12px;
  }
}

.short-term-goals {
  &-col {
    padding: 0 15px;
  }
}

.icon {
  display: flex;
  width: 12px;
  height: 12px;
  margin-right: 15px;

  &_create {
    background-image: url("../icons/add.svg");
    background-size: contain;
    background-repeat: no-repeat;
    fill: #fff;
  }
}

.division-title-wrapper {
  width: 100%;
  margin-right: 15px;
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.w {
  &-100 {
    width: 100%;
  }

  &-75 {
    width: 75%;
  }

  &-50 {
    width: 50%;
  }

  &-25 {
    width: 25%;
  }
}

.min-w {
  &-12 {
    min-width: 12%;
  }

  &-25 {
    min-width: 25%;
  }

  &-33 {
    min-width: 33.3%;
  }

  &-40 {
    min-width: 40%;
  }

  &-50 {
    min-width: 50%;
  }

  &-60 {
    min-width: 60%;
  }

  &-66 {
    min-width: 66%;
  }

  &-75 {
    min-width: 75%;
  }

  &-100 {
    min-width: 100%;
  }
}

.byerley-bold {
  font-weight: 600;
  color: #a0a0a0;
}

.short-report-policy-mtg {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eaeaea;
}

.short-report-policy-mtg:first-child {
  padding-top: 0px;
  margin-top: 0px;
  border-top: none;
}

.more-line-height {
  line-height: 19px;
}

.percent-30 {
  width: 30%;
  max-width: 30%;
  min-width: 30%;
}

.percent-52 {
  width: 52%;
  max-width: 52%;
  min-width: 52%;
}

.padding-left-30 {
  padding-left: 26px;
}

.delete-button:hover {
  background-color: #ffb102;
}

.textarea-field_min_height_reduced {
  min-height: 30px;
}

.textarea-field_margin_top {
  margin-top: 12px;
}

.customized {
  outline: none;
  padding: 0 5px 0;
  font-family: inherit;
  font-size: 12px;
  cursor: inherit;
  line-height: inherit;
}
